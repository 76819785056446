import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleFooterDisplay, handleSidebarDisplay } = useContext(AuthContext);
  handleFooterDisplay(true);
  handleSidebarDisplay(false);

  // Initial countdown value in seconds
  const initialCountdown = 15;
  
  // State for countdown
  const [countdown, setCountdown] = useState(initialCountdown);

  // Helper function to get query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const oid = searchParams.get("oid");
    const amount = searchParams.get("amount");
    return { oid, amount };
  };

  // Extract parameters
  const { oid, amount } = getQueryParams();

  // Countdown and redirect logic
  useEffect(() => {
    // Set an interval to update countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect after countdown ends
    const redirectTimeout = setTimeout(() => {
      if (oid && oid.includes("order")) {
        navigate("/student/wallet"); // Redirect to /student/wallet if oid contains "order"
      } else {
        navigate("/student/reservations"); // Redirect to home otherwise
      }
    }, initialCountdown * 1000);

    // Clear intervals and timeouts on component unmount
    return () => {
      clearInterval(interval);
      clearTimeout(redirectTimeout);
    };
  }, [oid, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-bkg dark:bg-content text-center">
        <h1 className="text-lg font-bold text-green-600 mb-4">
          Paiement réussie!
        </h1>
        {oid && !oid.includes("order") && (
          <p className="text-lg text-gray-700 mb-6">
            Votre réservation a été payée avec succès. Elle sera accessible après confirmation du professeur. Vous serez redirigé vers vos réservations dans {countdown} secondes.
          </p>
        )}
        {oid && oid.includes("order") && (
          <p className="text-lg text-gray-700 mb-6">
            Votre carte a été rechargée avec succès de {amount} MAD. Vous serez redirigé vers votre portefeuille dans {countdown} secondes.
          </p>
        )}

    </div>
  );
};

export default PaymentSuccess;
