import { useState, useEffect, useContext, useRef } from "react";
import Logo from "../assets/logo/SekWilla/final/logo.png";
import NotificationsList from "./NotificationsList";
import MessagesList from "./MessagesList";
import { UserType } from "../utils/UserType";
import { useNavigate } from "react-router-dom";
import NotificationService from "../services/NotificationService";
import MessageService from "../services/MessagesService";
import AuthContext from "../context/AuthContext";
import DiscussionContext from "../context/DiscussionContext";
import { useLocation } from "react-router-dom"; // To access the current path


function NavBar() {
  const {
    token,
    isAuthenticated,
    logout,
    user,
    theme,
    switchTheme,
  } = useContext(AuthContext);
  const { lastMessages } = useContext(DiscussionContext);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const [isMessageClicked, setIsMessageClicked] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);
  const [isMessagesChecked, setIsMessagesChecked] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true)
  const location = useLocation(); // Get the current path
  const svgRefs = {
    notification: useRef(null),
    message: useRef(null),
    profile: useRef(null),
    burger: useRef(null),
  };

  const element = document.documentElement;
  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");

  var lastNotifications = [];
  const navigate = useNavigate();

  const notificationService = new NotificationService();
  const messageService = new MessageService();

  function onWindowMatch() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }
  onWindowMatch();

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;
      default:
        localStorage.removeItem("theme");
        break;
    }
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!"theme" in localStorage) {
      if (e.matches) {
        element.classList.add("dark");
      } else {
        element.classList.remove("dark");
      }
    }
  });

  const handleSwitchClick = (given) => {
    switchTheme(given === "dark" ? "dark" : "light");
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    setIsNotificationClicked(!isNotificationClicked);
    setIsMessageClicked(false);
    setIsProfileClicked(false);
    setIsBurgerClicked(false);
  };

  const handleMessageClick = (e) => {
    e.stopPropagation();
    setIsMessageClicked(!isMessageClicked);
    setIsNotificationClicked(false);
    setIsProfileClicked(false);
    setIsBurgerClicked(false);
  };

  const handleProfileClick = (e) => {
    e.stopPropagation();
    setIsProfileClicked(!isProfileClicked);
    setIsNotificationClicked(false);
    setIsMessageClicked(false);
    setIsBurgerClicked(false);
  };

  const handleBurgerClick = (e) => {
    e.stopPropagation();
    setIsBurgerClicked(!isBurgerClicked);
    setIsNotificationClicked(false);
    setIsMessageClicked(false);
    setIsMessageClicked(false);
  };

  const logOut = () => {
    logout();
    navigate("/");
  };

  const handleClickOutside = (e) => {
    const { notification, message, profile } = svgRefs;

    if (
      (notification.current && !notification.current.contains(e.target)) ||
      (message.current && !message.current.contains(e.target)) ||
      (profile.current && !profile.current.contains(e.target))
    ) {
      setIsNotificationClicked(false);
      setIsMessageClicked(false);
      setIsProfileClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1060) {
        setIsBurgerClicked(false);
      }
    };

    // Add resize event listener to close burger menu on resize
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //update the red point on top of messages when lastMessages Array updates
  useEffect(() => {
    if (lastMessages) {
      const unreadLastMessages = lastMessages.filter(
        (msg) => msg.seen === false
      );
      setIsMessagesChecked(unreadLastMessages.length > 0);
    }
  }, [lastMessages]);

  // New useEffect to hide/show navbar and sidebar based on URL
  useEffect(() => {
    if (location.pathname.includes("session")) {
      // Hide both navbar and sidebar if "session" is in the URL
      setShowNavBar(false);
    } else {
      // Show them if "session" is not in the URL
      setShowNavBar(true);
    }
  }, [location.pathname]); // Trigger when the URL changes

  return (
    <div className={`${showNavBar ? 'flex' : 'hidden'} flex-col w-screen h-[8vh] md:h-[10vh] relative overflow-visible`}>
      {showNavBar && (
        <>
          <div
            className={`h-full w-full dark:bg-content bg-bkg flex flex-row justify-between px-10 sm:justify-between items-center sm:px-[7.5rem] sm:py-[0.875rem] `}
          >
            {/* Logo */}
            <div
              className="h-[7.40256rem] w-[6.25rem] xl:w-[9.25rem] xl:h-[10.40256rem] justify-center items-center flex cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={Logo} alt="Logo" className=" " />
            </div>

            {/* divider */}
            {/* <div className='hidden sm:block w-[0.0625rem] h-[3.125rem] bg-navborder'></div> */}
            {/* Links */}
            <div className="md:flex hidden justify-center items-center ">
              {(!isAuthenticated ||
                (user && user.type === UserType.Student)) && (
                <>
                  <div
                    className="flex p-[0.625rem] justify-center items-center list-none text-logo text-center font-montserrat text-base xl:text-3xl font-medium cursor-pointer"
                    onClick={() => navigate("/courses")}
                  >
                    Cours
                  </div>
                  <div
                    className="flex p-[0.625rem] justify-center items-center list-none text-logo text-center font-montserrat text-base xl:text-3xl font-medium cursor-pointer"
                    onClick={() => navigate("/professors")}
                  >
                    Professeurs
                  </div>
                  <div
                    className="flex p-[0.625rem] justify-center items-center list-none text-logo text-center font-montserrat text-base xl:text-3xl font-medium cursor-pointer"
                    onClick={() => navigate("/subjects")}
                  >
                    Matières
                  </div>
                </>
              )}
            </div>

            {/* Buttons (show only if not authenticated) */}
            {!isAuthenticated && (
              <div className="hidden md:flex  h-[3rem] xl:h-16 px-[1.5rem] py-[1rem] justify-center items-center gap-[1.3125rem]">
                <button
                  className="flex w-[7rem] xl:w-[7.5rem] h-[2.5rem] xl:h-16 px-[1.5rem] py-4 justify-center items-center border border-logo border-solid rounded-lg text-logo text-center font-montserrat text-base xl:text-xl font-normal whitespace-nowrap"
                  onClick={() => navigate("/signin")}
                >
                  Se connecter
                </button>
                <button
                  className="flex w-[7rem] xl:w-[7.5rem] h-[2.5rem] xl:h-16 px-[1.5rem] py-4 justify-center items-center rounded-lg text-bkg text-center xl:text-xl font-montserrat text-base font-normal whitespace-nowrap bg-gradient-to-br from-logo to-darkPink"
                  onClick={() => navigate("/joinus")}
                >
                  S'inscrire
                </button>
              </div>
            )}

            {/* Notifications, Messages And profile */}
            {isAuthenticated && user && (
              <div className="flex items-center gap-2 sm:gap-[0.8125rem]">
                {/* Notifications */}
                {/* <div className=" w-6 h-6 lg:w-12 xl:h-12 relative overflow-visible">
              <svg
                ref={svgRefs.notification}
                className={` ${
                  isNotificationClicked
                    ? "fill-logo stroke-white dark:stroke-content"
                    : "fill-none stroke-logo"
                } w-6 h-6 cursor-pointer xl:w-12 xl:h-12 `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                onClick={(e) => handleNotificationClick(e)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
              {isNotificationClicked && !isMessageClicked && (
                <NotificationsList notifications={lastNotifications} />
              )}
            </div> */}

                {/* Messages */}
                <div className="w-6 h-6 lg:w-12 xl:h-12 relative overflow-visible z-50">
                  {isMessagesChecked && (
                    <div className="absolute top-0 right-5 w-2 h-2 bg-buttonHome rounded-full"></div>
                  )}
                  <svg
                    ref={svgRefs.message}
                    className={` ${
                      isMessageClicked
                        ? "fill-logo stroke-white dark:stroke-content"
                        : "fill-none stroke-logo"
                    } w-6 h-6 cursor-pointer xl:w-12 xl:h-12`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    onClick={(e) => handleMessageClick(e)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  {!isNotificationClicked && isMessageClicked && (
                    <MessagesList messages={lastMessages} />
                  )}
                </div>

                {/* Profile */}
                <div className=" hidden md:flex flex-col w-6 h-6 xl:w-12 xl:h-12 relative overflow-visible z-50">
                  <img
                    ref={svgRefs.profile}
                    className="rounded-[50%] cursor-pointer w-6 h-6 xl:w-12 xl:h-12 "
                    src={user.profil.profilePicture}
                    alt=""
                    onClick={(e) => handleProfileClick(e)}
                  />
                  {!isNotificationClicked &&
                    !isMessageClicked &&
                    !isBurgerClicked &&
                    isProfileClicked &&
                    user.type === UserType.Student && (
                      <div className="flex flex-col w-max h-max items-center justify-start border bg-bkg dark:bg-content py-[0.5rem] px-[1.375rem] rounded-[0.375rem] border-solid stroke-content dark:stroke-bkg gap-[0.3125rem] pt-4 pb-4 z-10 whitespace-nowrap">
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/student/discussions");
                          }}
                        >
                          {" "}
                          Messages
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/student/reservations");
                          }}
                        >
                          {" "}
                          Réservations
                        </div>
                        {/* <div
                      className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                      onClick={() => {
                        setIsProfileClicked(false);
                        navigate("/student/invoices");
                      }}
                    >
                      {" "}
                      Factures
                    </div> */}
                        <div
                          to={`/favorites`}
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/student/favorites");
                          }}
                        >
                          {" "}
                          Favoris
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/student/wallet");
                          }}
                        >
                          {" "}
                          Portefeuille
                        </div>

                        <div className=" w-[100%] h-[0.0625rem] bg-navborder"></div>

                        <div
                          to={`/profile`}
                          className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/student/profile");
                          }}
                        >
                          Mon compte{" "}
                        </div>

                        <a
                          href=""
                          className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => logOut()}
                        >
                          Se déconnecter
                        </a>
                      </div>
                    )}
                  {!isNotificationClicked &&
                    !isMessageClicked &&
                    !isBurgerClicked &&
                    isProfileClicked &&
                    user.type === UserType.Professor && (
                      <div className=" flex flex-col items-center w-max h-max border bg-bkg dark:bg-content py-[0.5rem]  px-[1.375rem] rounded-[0.375rem] border-solid stroke-content dark:stroke-bkg whitespace-nowrap z-10">
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/professor/courses");
                          }}
                        >
                          Cours
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/professor/calendar");
                          }}
                        >
                          Calendrier
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/professor/discussions");
                          }}
                        >
                          Messages
                        </div>

                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/professor/wallet");
                          }}
                        >
                          Portefeuille
                        </div>

                        <div className="w-[3.125rem] h-[0.0625rem] bg-navborder"></div>
                        <div
                          className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/professor/profile");
                          }}
                        >
                          Mon compte{" "}
                        </div>

                        <a
                          href=""
                          className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => logOut()}
                        >
                          Se déconnecter
                        </a>
                      </div>
                    )}
                  {!isNotificationClicked &&
                    !isMessageClicked &&
                    !isBurgerClicked &&
                    isProfileClicked &&
                    user.type === UserType.Admin && (
                      <div className=" flex flex-col items-center w-max h-max border bg-bkg dark:bg-content py-[0.5rem]  px-[1.375rem] rounded-[0.375rem] border-solid stroke-content dark:stroke-bkg whitespace-nowrap z-10">
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/courses");
                          }}
                        >
                          Cours
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/professors");
                          }}
                        >
                          Professeurs
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/subjects");
                          }}
                        >
                          Matières
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/wallet");
                          }}
                        >
                          Portefeuille
                        </div>
                        <div
                          className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium  cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/statistics");
                          }}
                        >
                          Statistiques
                        </div>

                        <div className="w-[3.125rem] h-[0.0625rem] bg-navborder"></div>
                        <div
                          className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => {
                            setIsProfileClicked(false);
                            navigate("/admin/profile");
                          }}
                        >
                          Mon compte{" "}
                        </div>

                        <a
                          href=""
                          className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                          onClick={() => logOut()}
                        >
                          Se déconnecter
                        </a>
                      </div>
                    )}
                </div>
              </div>
            )}

            {/* Theme switchers */}
            <div className="flex w-[7.66763rem] h-16 justify-center items-center gap-2 sm:gap-[1.5625rem]">
              <svg
                className="dark:fill-logo dark:stroke-none stroke-logo fill-none w-6 h-6 xl:w-12 xl:h-12  cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                onClick={() => handleSwitchClick("dark")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="w-6 h-6 xl:w-12 xl:h-12 dark:fill-none fill-logo stroke-logo cursor-pointer"
                onClick={() => handleSwitchClick("light")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                />
              </svg>
            </div>

            {/* Burgur to toggle sidebar */}
            <div className="md:hidden">
              <div
                className={`relative w-6 h-6 overflow-visible justify-center items-center`}
              >
                <div
                  ref={svgRefs.burger}
                  className="w-6 h-6 inset-x-1/2"
                  onClick={(e) => handleBurgerClick(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    className="stroke-logo w-6 h-6 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile navbar content */}
          {isBurgerClicked && !isAuthenticated && (
            <div className="flex justify-center flex-col items-start w-screen h-max border-b bg-bkg dark:bg-content py-[0.5rem] px-[1.375rem]  border-solid stroke-content dark:stroke-navborder z-50 whitespace-nowrap">
              <div
                className="flex p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                onClick={() => {
                  setIsBurgerClicked(false);
                  navigate("/courses");
                }}
              >
                Cours
              </div>
              <div
                className="flex p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                onClick={() => {
                  setIsBurgerClicked(false);
                  navigate("/professors");
                }}
              >
                Professeurs
              </div>
              <div
                className="flex p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                onClick={() => {
                  setIsBurgerClicked(false);
                  navigate("/subjects");
                }}
              >
                Matières
              </div>

              {/* <div className='w-full h-[0.0625rem] bg-navborder'></div> */}
              <div
                className="flex p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                onClick={() => {
                  setIsBurgerClicked(false);
                  navigate("/signin");
                }}
              >
                Se connecter
              </div>
              <div
                className="flex p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                onClick={() => {
                  setIsBurgerClicked(false);
                  navigate("/joinus");
                }}
              >
                S'inscrire
              </div>
            </div>
          )}
          {isBurgerClicked &&
            user &&
            user.type === UserType.Student &&
            isAuthenticated && (
              <div className="flex justify-center flex-col items-start w-screen h-max border-b bg-bkg dark:bg-content py-[0.5rem] px-[1.375rem]  border-solid stroke-content dark:stroke-bkg z-50 whitespace-nowrap">
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/student/discussions");
                  }}
                >
                  {" "}
                  Messages
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/student/reservations");
                  }}
                >
                  {" "}
                  Réservations
                </div>
                {/* <div
              className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
              onClick={() => {
                setIsBurgerClicked(false);
                navigate("/student/invoices");
              }}
            >
              {" "}
              Factures
            </div> */}
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/student/favorites");
                  }}
                >
                  {" "}
                  Favoris
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/student/wallet");
                  }}
                >
                  {" "}
                  Portefeuille
                </div>

                <div className="w-[100%] h-[0.0625rem] bg-navborder"></div>

                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/courses");
                  }}
                >
                  {" "}
                  Cours
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professors");
                  }}
                >
                  Professeurs
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/subjects");
                  }}
                >
                  {" "}
                  Matières
                </div>

                <div className=" w-[100%] h-[0.0625rem] bg-navborder"></div>

                <div
                  className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/student/profile");
                  }}
                >
                  Mon compte{" "}
                </div>

                <a
                  href=""
                  className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    logOut();
                    setIsBurgerClicked(false);
                  }}
                >
                  Se déconnecter
                </a>
              </div>
            )}
          {isBurgerClicked &&
            user &&
            user.type === UserType.Professor &&
            isAuthenticated && (
              <div className=" flex justify-center flex-col items-start w-screen h-max border-b bg-bkg dark:bg-content py-[0.5rem] px-[1.375rem]  border-solid stroke-content dark:stroke-bkg z-50 whitespace-nowrap">
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/courses");
                  }}
                >
                  {" "}
                  Cours
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/calendar");
                  }}
                >
                  {" "}
                  Calendrier
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/discussions");
                  }}
                >
                  {" "}
                  Messages
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/wallet");
                  }}
                >
                  {" "}
                  Portefeuille
                </div>

                <div className=" w-[100%] h-[0.0625rem] bg-navborder"></div>

                <div
                  className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/profile");
                  }}
                >
                  Mon compte{" "}
                </div>

                <a
                  href=""
                  className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    logOut();
                    setIsBurgerClicked(false);
                  }}
                >
                  Se déconnecter
                </a>
              </div>
            )}
          {isBurgerClicked &&
            user &&
            user.type === UserType.Admin &&
            isAuthenticated && (
              <div className=" flex justify-center flex-col items-start w-screen h-max border-b bg-bkg dark:bg-content py-[0.5rem] px-[1.375rem]  border-solid stroke-content dark:stroke-bkg z-50 whitespace-nowrap">
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/admin/courses");
                  }}
                >
                  {" "}
                  Cours
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/admin/professors");
                  }}
                >
                  {" "}
                  Professeurs
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/admin/subjects");
                  }}
                >
                  {" "}
                  Matières
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/admin/wallet");
                  }}
                >
                  {" "}
                  Portefeuille
                </div>
                <div
                  className=" p-[0.625rem] justify-start items-center list-none text-content dark:text-bkg hover:opacity-50 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/admin/statistics");
                  }}
                >
                  {" "}
                  Statistiques
                </div>

                <div className=" w-[100%] h-[0.0625rem] bg-navborder"></div>

                <div
                  className="p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 text-center font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    setIsBurgerClicked(false);
                    navigate("/professor/profile");
                  }}
                >
                  Mon compte{" "}
                </div>

                <a
                  href=""
                  className="block p-[0.625rem] justify-center items-center list-none text-content dark:text-bkg opacity-50 hover:opacity-30 font-montserrat text-base font-medium cursor-pointer"
                  onClick={() => {
                    logOut();
                    setIsBurgerClicked(false);
                  }}
                >
                  Se déconnecter
                </a>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default NavBar;
