import React, {useContext} from 'react'
import noCourses from '../assets/placeholders/noCourses.svg'
import noCoursesDark from '../assets/placeholders/noCoursesDark.svg'
import AuthContext from '../context/AuthContext';

const NotFound = ()=> {
    const {theme, handleFooterDisplay, handleSidebarDisplay} = useContext(AuthContext)
    handleFooterDisplay(true)
    handleSidebarDisplay(false)
  return (
    <div className="flex flex-col items-center min-h-screen bg-bkg dark:bg-content"><div className=" justify-center items-center flex flex-col w-screen h-max">
    <h1 className="text-pink text-xl font-open-sans">
      Page introuvable.
    </h1>
    <img
      src={theme === "dark" ? noCoursesDark : noCourses}
      alt=""
      className="ss:scale-75 scale-75 "
    />
  </div></div>
  )
}
export default NotFound;
