import React, { useEffect, useContext, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr';
import DiscussionContext from '../context/DiscussionContext';
import {MessageType} from '../utils/MessageType';


moment.locale('fr');

export default function MessageListElement({ message }) {
    const { receipients, discussions } = useContext(DiscussionContext)
    const [senderName, setSenderName] = useState(null);

    useEffect(()=>{
        if(message && discussions) {
            const discussion = discussions.find(discussion => discussion?.discussion._id === message.discussionId)
            if(discussion){

                const receipient =discussion.recipient
                setSenderName(`${receipient?.firstname} ${receipient?.lastname}`)
            }
        }

    }, [message, discussions])

    return (
        <div className="w-[100%] h-20 bg-bkg hover:shadow-md hover:bg-opacity-50 rounded-md py-1 backdrop-blur-[100px] justify-start items-start gap-1 flex flex-row cursor-pointer border-b  dark:bg-opacity-10 dark:hover:bg-opacity-30 dark:border-none">
            {message && (
                <>
                    {/* Read or unread */}
                    <div className="w-10 h-10">
                        {message.seen === false ? (
                            < svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 92 106" fill="none">
                                <g filter="url(#filter0_dii_2813_8246)">
                                    <circle cx="39" cy="23" r="23" fill="url(#paint0_radial_2813_8246)" />
                                    <circle cx="39" cy="23" r="23" fill="url(#paint1_linear_2813_8246)" fillOpacity="0.9" style={{ mixBlendMode: 'overlay' }} />
                                    <circle cx="39" cy="23" r="22.5" stroke="url(#paint2_linear_2813_8246)" style={{ mixBlendMode: 'overlay' }} />
                                </g>
                                <defs>
                                    <filter id="filter0_dii_2813_8246" x="-14" y="-1" width="106" height="107" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="30" />
                                        <feGaussianBlur stdDeviation="15" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2813_8246" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2813_8246" result="shape" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.675 0 0 0 0 0.7855 0 0 0 0 1 0 0 0 1 0" />
                                        <feBlend mode="overlay" in2="shape" result="effect2_innerShadow_2813_8246" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="-1" />
                                        <feGaussianBlur stdDeviation="4" />
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.460156 0 0 0 0 0.561094 0 0 0 0 0.7125 0 0 0 1 0" />
                                        <feBlend mode="overlay" in2="effect2_innerShadow_2813_8246" result="effect3_innerShadow_2813_8246" />
                                    </filter>
                                    <radialGradient id="paint0_radial_2813_8246" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.2746 30.6667) rotate(-102.655) scale(56.0278 25.1269)">
                                        <stop stopColor="#F69CA5" />
                                        <stop offset="1" stopColor="#EA71C5" />
                                    </radialGradient>
                                    <linearGradient id="paint1_linear_2813_8246" x1="39" y1="-30.5469" x2="39" y2="46" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.0333845" stopColor="#C8D7FE" />
                                        <stop offset="0.576675" stopColor="#325EB3" stopOpacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_2813_8246" x1="33.3937" y1="31.9063" x2="53.9468" y2="2.79559" gradientUnits="userSpaceOnUse">
                                        <stop stopOpacity="0.5" />
                                        <stop offset="1" stopColor="white" stopOpacity="0.5" />
                                    </linearGradient>
                                </defs>
                            </svg>) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 83 88" fill="none">
                                <g filter="url(#filter0_bd_2813_8254)">
                                    <circle cx="39" cy="34" r="23" fill="url(#paint0_linear_2813_8254)" shapeRendering="crispEdges" />
                                    <circle cx="39" cy="34" r="23" stroke="url(#paint1_linear_2813_8254)" strokeOpacity="0.6" style={{ mixBlendMode: 'overlay', shapeRendering: 'crispEdges' }} />
                                </g>
                                <defs>
                                    <filter id="filter0_bd_2813_8254" x="-34.5" y="-39.5" width="147" height="147" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2813_8254" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="10" />
                                        <feGaussianBlur stdDeviation="10" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.196078 0 0 0 0 0.32549 0 0 0 0 0.54902 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="effect1_backgroundBlur_2813_8254" result="effect2_dropShadow_2813_8254" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2813_8254" result="shape" />
                                    </filter>
                                    <linearGradient id="paint0_linear_2813_8254" x1="39" y1="4.33333" x2="39" y2="57" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#C5CCDE" />
                                        <stop offset="1" stopColor="#D1DBE6" stopOpacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_2813_8254" x1="39" y1="11" x2="39" y2="57" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white" />
                                        <stop offset="1" stopColor="white" stopOpacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>)}
                    </div>

                    <div className="w-[90%] h-[90%] flex-col justify-start items-start gap-1 inline-flex">
                        {/* Notification sender */}
                        <div className='w-[95%] flex flex-row justify-between items-start '>
                            <h3 className="w-max max-w-[60%] text-content text-xs font-semibold font-inter whitespace-nowrap overflow-hidden overflow-ellipsis capitalize dark:text-white">{senderName}</h3>
                            <h5 className="w-max grow text-content text-opacity-60 text-xs font-normal font-inter leading-tight whitespace-nowrap text-end pr-2 dark:text-white">
                                {moment(message.createdAt).format('LT')}
                            </h5>
                        </div>

                        {/* Notification content */}
                        {message.type === MessageType.Text && (
                            <h5 className="w-[90%] text-content text-opacity-60 text-xs font-normal font-inter leading-tight whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-white">
                                {message.content}
                            </h5>
                        )}

                        {message.type === MessageType.File && (
                            <h5 className="w-[90%] text-content text-opacity-60 text-xs font-normal font-inter leading-tight whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-white">
                                Nouveau fichier
                            </h5>
                        )}

                        {message.type === MessageType.Reservation && (
                            <h5 className="w-[90%] text-content text-opacity-60 text-xs font-normal font-inter leading-tight whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-white">
                                Reservation
                            </h5>
                        )}
                    </div>
                </>
            )}

        </div >
    )
}
