import axios from 'axios';
import { API_URL } from '../config/config';

class NotificationService {
  
  constructor() {
    this.baseUrl = API_URL;
  }

  async getUserLastNotifications() {
    try{
      const response = await axios.get(`${this.baseUrl}/notifications/last`)
      const data = response.data;
      return data;

    }
    catch(error){
      //console.log(error)
      return []
    }
  }

  

  

  


}

export default NotificationService;
