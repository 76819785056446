import axios from "axios";
import professors from "../assets/jsons/professor.json";
import { API_URL } from "../config/config";

class ProfessorService {
  constructor() {
    this.baseUrl = API_URL;
  }

  async getAllProfessors() {
    try {
      const response = await axios.get(`${this.baseUrl}/professors`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all professors:", error);
      throw error;
    }
  }

  async getProfessorsBySubject(subject) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/subject?subject=${subject}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching professors by subject:", error);
      throw error;
    }
  }

  async getProfessorById(professorId, populate = null) {
    try {
      if (populate) {
        const response = await axios.get(
          `${
            this.baseUrl
          }/professors/id?idProfessor=${professorId}&populate=${true}`
        );
        const data = response.data;
        return data;
      } else {
        const response = await axios.get(
          `${this.baseUrl}/professors/id?idProfessor=${professorId}`
        );
        const data = response.data;
        return data;
      }
    } catch (error) {
      console.error("Error fetching professor by ID:", error);
      throw error;
    }
  }

  async getFilteredProfessors(filters, page, limit, idSubject, isRestricted) {
    try {
      //console.log(filters, page, limit, idSubject, isRestricted);
      if (isRestricted === false) {
        if (idSubject) {
          const queryParams = new URLSearchParams();
          // Append each filter parameter to the URL
          Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              if (value.length > 0) {
                queryParams.append(key, value.join(","));
              }
            } else if (value) {
              queryParams.append(key, value);
            }
          });

          const queryString = queryParams.toString();
          const url = queryString
            ? `${this.baseUrl}/professors/filtered?${queryString}&limit=${limit}&page=${page}&idSubject=${idSubject}&isRestricted=${isRestricted}`
            : `${this.baseUrl}/professors/filtered?limit=${limit}&page=${page}&idSubject=${idSubject}&isRestricted=${isRestricted}`;

          //console.log("Filtered Professors URL:", url);

          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          const professors = response.data;
          //console.log("Filtered Professors:", professors);
          return professors;
        } else {
          const queryParams = new URLSearchParams();
          // Append each filter parameter to the URL
          Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              if (value.length > 0) {
                queryParams.append(key, value.join(","));
              }
            } else if (value) {
              queryParams.append(key, value);
            }
          });

          const queryString = queryParams.toString();
          const url = queryString
            ? `${this.baseUrl}/professors/filtered?${queryString}&limit=${limit}&page=${page}&isRestricted=${isRestricted}`
            : `${this.baseUrl}/professors/filtered?limit=${limit}&page=${page}&isRestricted=${isRestricted}`;

          //console.log("Filtered Professors URL:", url);

          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          const professors = response.data;
          //console.log("Filtered Professors:", professors);
          return professors;
        }
      } else {
        if (idSubject) {
          const queryParams = new URLSearchParams();
          // Append each filter parameter to the URL
          Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              if (value.length > 0) {
                queryParams.append(key, value.join(","));
              }
            } else if (value) {
              queryParams.append(key, value);
            }
          });

          const queryString = queryParams.toString();
          const url = queryString
            ? `${this.baseUrl}/professors/filtered?${queryString}&limit=${limit}&page=${page}&idSubject=${idSubject}`
            : `${this.baseUrl}/professors/filtered?limit=${limit}&page=${page}&idSubject=${idSubject}`;

          //console.log("Filtered Professors URL:", url);

          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          const professors = response.data;
          //console.log("Filtered Professors:", professors);
          return professors;
        } else {
          const queryParams = new URLSearchParams();
          // Append each filter parameter to the URL
          Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              if (value.length > 0) {
                queryParams.append(key, value.join(","));
              }
            } else if (value) {
              queryParams.append(key, value);
            }
          });

          const queryString = queryParams.toString();
          const url = queryString
            ? `${this.baseUrl}/professors/filtered?${queryString}&limit=${limit}&page=${page}`
            : `${this.baseUrl}/professors/filtered?limit=${limit}&page=${page}`;

          //console.log("Filtered Professors URL:", url);

          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          const professors = response.data;
          //console.log("Filtered Professors:", professors);
          return professors;
        }
      }
    } catch (error) {
      console.error("Error fetching filtered professors", error);
      throw error;
    }
  }

  async getTopProfessors() {
    var topProfessors = [
      professors.professor,
      professors.professor,
      professors.professor,
    ];
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/topProfessors`
      );
      if (response) {
        topProfessors = response.data;
      }
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    } finally {
      return topProfessors;
    }
  }

  async getColorByProfId(profId) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/subjectColor/${profId}`
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  async getProfDisponibilitiesMonth(professorId) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/disponibilitiesMonth?idProf=${professorId}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching professor disponibilitiesMonth:", error);
      throw error;
    }
  }

  async getProfDisponibilities(professorId) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/disponibilities?idProf=${professorId}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching professor disponibilities:", error);
      throw error;
    }
  }

  async getProfDisponibilitiesFiltered(professorId) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/disponibilitiesFiltered?idProf=${professorId}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching professor disponibilities:", error);
      throw error;
    }
  }

  async getProfDisponibilitiesMonthModes(professorId, mode) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/disponibilitiesMonth/modes?idProf=${professorId}&mode=${mode}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching professor disponibilitiesMonth:", error);
      throw error;
    }
  }

  async createProfDisponibilities(idProf, day, newDispo) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/professors/disponibilities`,
        {
          idProf,
          day,
          newDispo,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error creating professor disponibility:", error);
      throw error;
    }
  }

  async updateProfDisponibility(idProf, day, prevDispo, updatedDispo) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/disponibilities`,
        {
          idProf,
          day,
          prevDispo,
          updatedDispo,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error updating professor disponibility:", error);
      throw error;
    }
  }

  async deleteProfDisponibility(idProf, day, prevDispo) {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/professors/disponibilities`,
        {
          data: {
            idProf,
            day,
            prevDispo,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(
        "Error deleting professor disponibility:",
        error.response.status,
        error.response.data
      );
      throw error;
    }
  }

  async createProfDisponibilitiesMonth(idProf, day, newDispo) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/professors/disponibilitiesMonth`,
        {
          idProf,
          day,
          newDispo,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error creating professor disponibility:", error);
      throw error;
    }
  }

  async updateProfDisponibilityMonth(idProf, day, prevDispo, updatedDispo) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/disponibilitiesMonth`,
        {
          idProf,
          day,
          prevDispo,
          updatedDispo,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error updating professor disponibility:", error);
      throw error;
    }
  }

  async deleteProfDisponibilityMonth(idProf, day, prevDispo) {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/professors/disponibilitiesMonth`,
        {
          data: {
            idProf,
            day,
            prevDispo,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(
        "Error deleting professor disponibility:",
        error.response.status,
        error.response.data
      );
      throw error;
    }
  }

  async createProfTempDisponibility(idProf, day, newDispo, week) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/professors/tempdisponibility`,
        {
          idProf,
          day,
          newDispo,
          week,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error creating temp professor disponibility:", error);
      throw error;
    }
  }

  async updateProfessorRestriction(idProf, restriction) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/restriction`,
        {
          idProf,
          restriction,
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error updating professor restriction:", error);
      throw error;
    }
  }

  async updateProfTempDisponibility(idProf, day, index, updatedDispo) {
    //console.log("this is from the day calendar : update");
    // try {
    //     const response = await axios.patch(`${this.baseUrl}/professors/disponibilities`, {
    //         idProf,
    //         day,
    //         index,
    //         updatedDispo
    //     });
    //     const data = response.data;
    //     return data;
    // } catch (error) {
    //     console.error('Error updating professor disponibility:', error);
    //     throw error;
    // }
  }

  async deleteProfTempDisponibility(idProf, day, index) {
    // try {
    //   const response = await axios.delete(`${this.baseUrl}/professors/disponibilities`, {
    //     data: {
    //       idProf,
    //       day,
    //       index
    //     }
    //   });
    //   const data = response.data;
    //   return data;
    // } catch (error) {
    //   console.error('Error deleting professor disponibility:', error.response.status, error.response.data);
    //   throw error;
    // }
  }

  async getProfCourses(idProf, page, limit) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/professors/courses?idProf=${idProf}&page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching prof courses:", error);
      throw error;
    }
  }

  async addSignal(idProf, signal) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/signal/${idProf}`,
        signal
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateProfessorProfile(professorId, updatedProfessorData) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/profile/id?idProfessor=${professorId}`,
        updatedProfessorData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error updating professor profile:", error);
      throw error;
    }
  }

  async restrictProfessor(idProf, restriction) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/professors/restriction`,
        { idProf, restriction }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async getProfessorVirtualCard(idProf) {
    try {
      const response = await axios.get(`${this.baseUrl}/virtualCard/${idProf}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching Virtual Card prof:", error);
      throw error;
    }
  }

  async updateVirtualCardBalance(id, newBalance, transactionType) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/virtualCard/${id}/${transactionType}`,
        { balance: newBalance }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating balance virtual Card:", error);
      throw error;
    }
  }

  async canBeDeleted(id){
    try {    
      const response = await axios.get(`${this.baseUrl}/professors/canbedeleted/${id}`);
      return response.data
    } catch (error) {
      //console.log(error)
      throw error
    }
  }

  async deleteProfById(id){
    try {    
      const response = await axios.delete(`${this.baseUrl}/professors/${id}`);
      return response.data
    } catch (error) {
      //console.log(error)
      throw error
    }
  }
}

export default ProfessorService;
