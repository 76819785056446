import axios from "axios";
import { API_URL } from '../config/config';

class WalletService {
  constructor() {
    this.baseUrl = API_URL + "/wallet";
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() returns a zero-based index
    let day = date.getDate();

    // Add a leading zero to the month and day if they are less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }

  async getBalanceOfDay(idProf, day) {
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idProf=${idProf}&statType=day&day=${this.formatDate(day)}`);
      return response;
    } catch (error) {
      //console.log('here day')
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceOfMonth(idProf, month, year) {
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idProf=${idProf}&statType=month&month=${month}&year=${year}`);
      return response;
    } catch (error) {
      //console.log('here month')
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceOfYear(idProf, year) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/stats?idProf=${idProf}&statType=year&year=${year}`
      );
      return response;
    } catch (error) {
      //console.log('here year')
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceByDay(idProf, startOfWeek, endOfWeek){
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idProf=${idProf}&statType=days&startOfWeek=${this.formatDate(startOfWeek)}&endOfWeek=${this.formatDate(endOfWeek)}`)
      return response
    } catch (error) {
      throw error
    }
  }

  async getBalanceByWeek(idProf, month, year){
    try {
        //console.log(month, year)
      const response = await axios.get(`${this.baseUrl}/stats?idProf=${idProf}&statType=weeks&month=${month}&year=${year}`)
      //console.log(response.data)
      return response
    } catch (error) {
      throw error
    }
  }

  async getBalanceByMonth(idProf, year){
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idProf=${idProf}&statType=months&year=${year}`)
      return response
    } catch (error) {
      throw error
    }
  }

  async addProfBalance(idProf, balance){
    try {
      const response = await axios.post(`${this.baseUrl}/${idProf}`, balance)
      return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
  }

}

export default WalletService;
