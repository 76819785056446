import React, { useState, useEffect } from "react";
import AuthContext from "./AuthContext";
import UserManagementService from "../services/UserManagementService";

const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("auth-token"));
  const [isAuthenticated, setIsAuthenticated] = useState(token !== null && token !== '');
  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [showFooter, setShowFooter] = useState(true);
  const [userType, setUserType] = useState(null);

  const [showSidebar, setShowSidebar] = useState(true);

  const userManagementService = new UserManagementService();

  const login = async (newToken, userDetails) => {
    setToken(newToken);
    setIsAuthenticated(true);
    setUser(userDetails);
    setUserType(userDetails.type);
    localStorage.setItem("auth-token", newToken);
  };

  const logout = () => {
    setToken("");
    setIsAuthenticated(false);
    setUserType(null);
    localStorage.removeItem("auth-token");
  };

  const switchTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const handleFooterDisplay = (display) => {
    setShowFooter(display);
  };

  const handleSidebarDisplay = (display) => {
    setShowSidebar(display);
  };

  const onNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateUser = async (newUserData) => {
    setUser(newUserData);
  };

  useEffect(() => {
    const onStorageChange = (event) => {
      if (event.key === "auth-token") {
        const updatedToken = event.newValue;
        setToken(updatedToken);
      }
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const currentUser = await userManagementService.getCurrentUser(token);
          if (currentUser && currentUser.valid === false) {
            setIsAuthenticated(false);
            setUser(null);
            return;
          }
          setUser(currentUser);
          setUserType(currentUser.type);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Error fetching current user:", error);
        }
      }
    };

    fetchData();
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuthenticated,
        user,
        login,
        logout,
        theme,
        switchTheme,
        showFooter,
        handleFooterDisplay,
        updateUser,
        showSidebar,
        handleSidebarDisplay,
        onNavigate,
        setUserType,
        userType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
