import axios from "axios";
import { API_URL } from '../config/config';
class AdminWalletService {
  constructor() {
    this.baseUrl = API_URL+"/adminWallet";
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() returns a zero-based index
    let day = date.getDate();

    // Add a leading zero to the month and day if they are less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }

  async getBalanceOfDay(idAdmin, day) {
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=day&day=${this.formatDate(day)}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceOfMonth(idAdmin, month, year) {
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=month&month=${month}&year=${year}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceOfYear(idAdmin, year) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=year&year=${year}`
      );
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceByDay(idAdmin, startOfWeek, endOfWeek){
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=days&startOfWeek=${this.formatDate(startOfWeek)}&endOfWeek=${this.formatDate(endOfWeek)}`)
      return response
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceByWeek(idAdmin, month, year){
    try {
        //console.log(month, year)
      const response = await axios.get(`${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=weeks&month=${month}&year=${year}`)
      //console.log(response.data)
      return response
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
  }

  async getBalanceByMonth(idAdmin, year){
    try {
      const response = await axios.get(`${this.baseUrl}/stats?idAdmin=${idAdmin}&statType=months&year=${year}`)
      return response
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
  }

  async getStudentsVirtualCardsBalanace(){
    try {
        const response = await axios.get(`${API_URL}/virtualCard/students`)
        return response
      } catch (error) {
          throw error.response ? error.response.data : error.message;
      }
  }

  async getProfessorsVirtualCardsBalanace(){
    try {
        const response = await axios.get(`${API_URL}/virtualCard/professors`)
        return response
      } catch (error) {
          throw error.response ? error.response.data : error.message;
      }
  }

  async addAdminBalance(balance){
    try {
      const response = await axios.post(`${this.baseUrl}`, balance)
      return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
  }

}

export default AdminWalletService;
