import axios from "axios";
import { API_URL } from '../config/config';

const BASE_URL = API_URL;

class UserManagementService {
    async registerStudent(studentData) {
        try {
            const response = await axios.post(
                `${BASE_URL}/studentRegister`,
                studentData
            );
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error.message;
        }
    }

    async registerProfessor(professorData) {
        try {
            const response = await axios.post(
                `${BASE_URL}/profRegister`,
                professorData
            );
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error.message;
        }
    }

    async login(loginData) {
        //console.log("Login Request Data:", loginData);

        try {
            const response = await axios.post(`${BASE_URL}/login`, loginData);

            const newToken = response?.headers?.authorization?.split(" ")[1]; // Extract token from headers
            const userDetails = response?.data?.userDetails; // Extract userDetails from data

            return { userDetails, newToken }; // Return userDetails instead of the entire response
        } catch (error) {
            //console.error('Error in login:', error.response.status);
            throw error;
        }
    }

    async sendRecoveryEmail(email) {
        try {
            const response = await axios.post(
                `${BASE_URL}/send_recovery_email`,
                { email }
            );
            //console.log("Full Response:", response);
            //console.log("Response Data:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error in sendRecoveryEmail:", error);
            throw error.response ? error.response.data : error.message;
        }
    }

    async verifyOTP(email, userOTP) {
        try {
            const response = await axios.post(`${BASE_URL}/verify_otp`, {
                email,
                userOTP,
            });
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error.message;
        }
    }

    async changePassword(email, newPassword) {
        try {
            const response = await axios.post(`${BASE_URL}/change-password`, {
                email,
                newPassword,
            });
            //console.log(response.data);
        } catch (error) {
            console.error("Error changing password:", error);
            throw error
        }
    }

    async getCurrentUser(token) {
        try {
            const response = await axios.get(`${BASE_URL}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                return response.data;
            } else if (response.status === 400) {
                return { valid: false };
            }
        } catch (error) {
            console.error("Error in getCurrentUser:", error.message);
            throw error;
        }
    }

    async changeOldPassword(email, oldPassword, newPassword) {
        try {
            const response = await axios.post(`${BASE_URL}/changeOldPassword`, {
                email,
                oldPassword,
                newPassword,
            });
            //console.log(response.data);
        } catch (error) {
            throw error;
        }
    }
}

export default UserManagementService;
