import React, { useState, useEffect, useContext, useRef } from "react";
import SessionContext from "./SessionContext";
import { io } from "socket.io-client";
import DiscussionContext from "./DiscussionContext";
import { UserType } from "../utils/UserType";
import StudentService from "../services/StudentService";
import ProfessorService from "../services/ProfessorService";

const SessionContextProvider = ({ children, user }) => {
  // Permissions
  const [micPermission, setMicPermission] = useState(false);
  const [camPermission, setCamPermission] = useState(false);
  const [screenPermission, setScreenPermission] = useState(false);

  // Streams and tracks
  const [localStream, setLocalStream] = useState(null);
  const [localScreenStream, setLocalScreenStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [remoteScreenStream, setRemoteScreenStream] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);

  // Control buttons
  const [localMicActive, setLocalMicActive] = useState(false);
  const [localCamActive, setLocalCamActive] = useState(false);
  const [localScreenActive, setLocalScreenActive] = useState(false);
  const [isSessionStarted, setIsSessionStarted] = useState(false);

  // webSockets
  const { socket } = useContext(DiscussionContext);

  //WebRTC
  const peerConfigurations = {
    iceServers: [
      {
        urls: ["stun:stun.l.google.com:19302", "stun:stun1.l.google.com:19302"],
      },
    ],
  };
  const [peerConnection, setPeerConnection] = useState(null);
  const [offer, setOffer] = useState(null);
  var didIOffer = null; // to distinguish between the offrer and the answere
  const [waitAnswer, setWaitAnswer] = useState(false);
  const [close, setClose] = useState(false);
  //Reservation
  const [reservation, setReservation] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const studentService = new StudentService();
  const professorService = new ProfessorService();

  

  const askForPermissions = async (filter) => {
    try {
      // Get audio and video stream
      const commingStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 },
        },
        audio: true,
      });
      let newVideoTrack = commingStream
        .getTracks()
        .find((track) => track.kind === "video");
      let newAudioTrack = commingStream
        .getTracks()
        .find((track) => track.kind === "audio");
      setLocalStream(commingStream);
      setVideoTrack(newVideoTrack);
      setAudioTrack(newAudioTrack);

      setCamPermission(true);
      setMicPermission(true);

      if (filter === "cam") {
        setLocalCamActive(true);
      }
      if (filter === "mic") {
        setLocalMicActive(true);
      }
    } catch (error) {
      console.error("Error requesting media permissions:", error);
      setMicPermission(false);
      setCamPermission(false);
      setLocalCamActive(false);
      setLocalMicActive(false);
    }
  };

  const askForScreenPermission = async () => {
    try {
      // Get screen stream
      const commingScreenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" },
      });
      setLocalScreenStream(commingScreenStream);
      setScreenPermission(true);
      setLocalScreenActive(true);
    } catch (error) {
      console.error("Error requesting screen permissions:", error);
      setScreenPermission(false);
      setLocalScreenActive(false);
    }
  };

  // Setting up the recipient
  useEffect(() => {
    const fetchRecipient = async () => {
      if (user?.type === UserType.Professor) {
        const student = await studentService.getStudentById(
          reservation.student
        );
        setRecipient(student);
      } else {
        const professor = await professorService.getProfessorById(
          reservation.professor._id
        );
        setRecipient(professor);
      }
    };
    if (reservation && user) {
      fetchRecipient();
    }
  }, [reservation, user]);

  // Setting up audio and video tracks
  useEffect(() => {
    if (localStream) {
      let newVideoTrack = localStream
        .getTracks()
        .find((track) => track.kind === "video");
      let newAudioTrack = localStream
        .getTracks()
        .find((track) => track.kind === "audio");

      setVideoTrack(newVideoTrack);
      setAudioTrack(newAudioTrack);

    }
  }, [localStream]);

  // To enable/disable video/audio tracks when clicking on control buttons
  useEffect(() => {
    checkStream();
  }, [localCamActive, localMicActive]);

  const cleanUp = () => {
    localStream && localStream.getTracks().forEach((track) => track.stop());
    localScreenStream &&
      localScreenStream.getTracks().forEach((track) => track.stop());
    setCamPermission(false);
    setMicPermission(false);
    setScreenPermission(false);
    setLocalStream(null);
    setLocalScreenStream(null);
    setClose(false);
  };

  // To enable/disable video/audio tracks
  const checkStream = () => {
    if (localStream) {
      if (localCamActive === true) {
        videoTrack.enabled = true;
      } else {
        videoTrack.enabled = false;
      }
      if (localMicActive === true) {
        audioTrack.enabled = true;
      } else {
        audioTrack.enabled = false;
      }
    }
  };

  // Control buttons functions
  const toggleMic = async () => {
    if (micPermission) {
      setLocalMicActive(!localMicActive);
    } else {
      await askForPermissions("mic");
    }
  };

  const toggleCam = async () => {
    if (camPermission) {
      setLocalCamActive(!localCamActive);
    } else {
      await askForPermissions("cam");
    }
  };

  const toggleScreen = async () => {
    if (screenPermission) {
      if (localScreenActive === true) {
        setLocalScreenActive(false);
        localScreenStream &&
          localScreenStream.getTracks().find((track) => track.stop());
        setScreenPermission(false);
      }
    } else {
      await askForScreenPermission();
    }
  };

  const startSession = async () => {
    console.log("start session");
    if (offer === null) {
      didIOffer = true;
      const createdPeerConnection = new RTCPeerConnection(peerConfigurations);
      setRemoteStream(new MediaStream());
      setRemoteScreenStream(new MediaStream());
      // add tracks to the peer connection
      localStream?.getTracks().forEach((track) => {
        createdPeerConnection?.addTrack(track, localStream);
      });

      localScreenStream?.getTracks().forEach((track) => {
        createdPeerConnection?.addTrack(track, localScreenStream);
      });
      
      // signaling state listener
      createdPeerConnection.addEventListener(
        "signalingstatechange",
        (event) => {
          //console.log(event);
          //console.log(createdPeerConnection?.signalingState);
        }
      );

      // icecandidates listener
      createdPeerConnection.addEventListener("icecandidate", (e) => {
        //console.log("..........Ice candidate found!.........");
        //console.log(e);
        if (e.candidate) {
          socket.emit("sendIceCandidateToSignalingServer", {
            iceCandidate: e.candidate,
            iceUserId: user.profil._id,
            destId: recipient?._id,
            didIOffer,
          });
        }
      });

      // track listener
      createdPeerConnection.addEventListener("track", (e) => {
        //console.log("Got a track from the other peer!! How exciting");
        //console.log(e);
        if (e.streams[0]) {
          e.streams[0].getTracks().forEach((track) => {
            if (remoteStream) {
              remoteStream.addTrack(track, remoteStream);
              setRemoteStream(remoteStream);
            }
            else{
              const newRemoteStream = new MediaStream();
              newRemoteStream.addTrack(track, newRemoteStream);
              setRemoteStream(newRemoteStream);

            }
          });
        }
        //setRemoteStream(newRemoteStream);

        /* if (e.streams[1]) {
          e.streams[1].getTracks().forEach((track) => {
            if (remoteScreenStream) {
              remoteScreenStream.addTrack(track, remoteScreenStream);
              if (remoteScreenRef.current) {
                remoteScreenRef.current.srcObject = remoteScreenStream;
                console.log("added");
              }
            }
            else{
              const newRemoteScreenStream = new MediaStream();
              newRemoteScreenStream.addTrack(track, newRemoteScreenStream);
              if (remoteScreenRef.current) {
                remoteScreenRef.current.srcObject = newRemoteScreenStream;
                console.log("added");
              }
              setRemoteScreenStream(newRemoteScreenStream);

            }

            //console.log("Here's an exciting moment... fingers crossed");
          });
        } */
        //setRemoteScreenStream(newRemoteScreenStream);

        if (isSessionStarted === false) {
          setIsSessionStarted(true);
          setWaitAnswer(false);
        }
      });

      try {
        const offerObj = await createdPeerConnection.createOffer();
        setOffer(offerObj);
        createdPeerConnection.setLocalDescription(offerObj);
        socket.emit("newOffer", {
          offer: offerObj,
          destId: recipient?._id,
          senderId: user.profil._id,
        });
        setWaitAnswer(true);
        setPeerConnection(createdPeerConnection);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("blast answer");
      didIOffer = false;
      const createdPeerConnection = new RTCPeerConnection(peerConfigurations);
      setRemoteStream(new MediaStream());
      setRemoteScreenStream(new MediaStream());
      // add tracks to the peer connection
      localStream?.getTracks().forEach((track) => {
        createdPeerConnection?.addTrack(track, localStream);
      });

      localScreenStream?.getTracks().forEach((track) => {
        createdPeerConnection?.addTrack(track, localScreenStream);
      });

      // signaling state listener
      createdPeerConnection.addEventListener(
        "signalingstatechange",
        (event) => {
          //console.log(event);
          //console.log(createdPeerConnection?.signalingState);
        }
      );

      // icecandidates listener
      createdPeerConnection.addEventListener("icecandidate", (e) => {
        //console.log("..........Ice candidate found!.........");
        //console.log(e);
        if (e.candidate) {
          //console.log(didIOffer);
          socket.emit("sendIceCandidateToSignalingServer", {
            iceCandidate: e.candidate,
            iceUserId: user.profil._id,
            destId: recipient?._id,
            didIOffer,
          });
        }
      });

      // track listener
      createdPeerConnection.addEventListener("track", (e) => {
        //console.log("Got a track from the other peer!! How exciting");
        console.log(e);
        if (e.streams[0]) {
          e.streams[0].getTracks().forEach((track) => {
            if (remoteStream) {
              remoteStream.addTrack(track, remoteStream);
              setRemoteStream(remoteStream);
            }
            else{
              const newRemoteStream = new MediaStream();
              newRemoteStream.addTrack(track, newRemoteStream);
              setRemoteStream(newRemoteStream);
            }

            //console.log("Here's an exciting moment... fingers crossed");
          });
        }

        /* if (e.streams[1]) {
          e.streams[1].getTracks().forEach((track) => {
            remoteScreenStream.addTrack(track, remoteScreenStream);
            if (remoteScreenRef.current) {
              remoteScreenRef.current.srcObject = remoteScreenStream;
              console.log("added");
            }
            console.log("Here's an exciting moment... fingers crossed");
          });
        } */
        //setRemoteScreenStream(newRemoteScreenStream);
        if (isSessionStarted === false) {
          setIsSessionStarted(true);
          setWaitAnswer(false);
        }
      });

      if (createdPeerConnection) {
        await createdPeerConnection.setRemoteDescription(offer?.offer);
      }
      if (createdPeerConnection?.signalingState === "have-remote-offer") {
        console.log(createdPeerConnection?.signalingState);
        // Create answer
        const answer = await createdPeerConnection?.createAnswer({});
        while (!answer) {
          console.log("ba9i majat");
        }
        await createdPeerConnection?.setLocalDescription(answer);
        const newOffer = { ...offer, answer };
        setOffer(newOffer);
        const offerIceCandidates = await socket.emitWithAck(
          "newAnswer",
          newOffer
        );
        offerIceCandidates.forEach((c) => {
          peerConnection?.addIceCandidate(c);
          //console.log("======Added Ice Candidate======");
        });
        setWaitAnswer(false);
        setPeerConnection(createdPeerConnection);
      }
    }
  };
  
  const quitSession = () => {
    localStream && localStream.getTracks().forEach((track) => track.stop());
    localScreenStream &&
      localScreenStream.getTracks().forEach((track) => track.stop());
    setCamPermission(false);
    setMicPermission(false);
    setLocalCamActive(false);
    setLocalMicActive(false);
    setLocalScreenActive(false);
    setScreenPermission(false);
    setLocalStream(null);
    setLocalScreenStream(null);
    setRemoteScreenStream(null);
    setRemoteStream(null);
    setReservation(null);
    setIsSessionStarted(false);
    setOffer(null);
    didIOffer = false;
    setWaitAnswer(false);
    //peerConnection && peerConnection.close();
    setPeerConnection(null);
    if (socket) {
      socket.emit("close", { userId: user.profil._id });
    }
    setClose(true);
  };

  const addAnswer = async (offerObj) => {
    //addAnswer is called in socketListeners when an answerResponse is emitted.
    //at this point, the offer and answer have been exchanged!
    //now CLIENT1 needs to set the remote
    //console.log(peerConnection?.signalingState)
    //console.log("add answer", offerObj);
    await peerConnection?.setRemoteDescription(offerObj.answer);
  };

  if (socket) {
    //someone just made a new offer and we're already here
    socket.on("newOfferAwaiting", (offerObj) => {
      setOffer(offerObj);
      /* console.log("offer awaiting");
      console.log(offer); */
    });

    socket.on("answerResponse", (offerObj) => {
      setOffer(offerObj);
      addAnswer(offerObj);
    });

    socket.on("receivedIceCandidateFromServer", (iceCandidate) => {
      //console.log(iceCandidate);
      peerConnection?.addIceCandidate(iceCandidate);
      //console.log("======Added Ice Candidate======");
    });

    socket.on("close", () => {
      quitSession();
    });
  }

  return (
    <SessionContext.Provider
      value={{
        micPermission,
        setMicPermission,
        camPermission,
        setCamPermission,
        screenPermission,
        setScreenPermission,
        localStream,
        setLocalStream,
        localScreenStream,
        setLocalScreenStream,
        isSessionStarted,
        setIsSessionStarted,
        askForPermissions,
        cleanUp,
        localMicActive,
        setLocalMicActive,
        localCamActive,
        setLocalCamActive,
        localScreenActive,
        setLocalScreenActive,
        askForScreenPermission,
        toggleMic,
        toggleCam,
        toggleScreen,
        startSession,
        quitSession,
        recipient,
        remoteStream,
        setRemoteStream,
        remoteScreenStream,
        setRemoteScreenStream,
        waitAnswer,
        setWaitAnswer,
        setReservation,
        close,
        setClose,
/*         videoRef,
        screenRef,
        remoteVideoRef,
        remoteScreenRef, */
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
