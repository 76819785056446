import React, {useState, useEffect, useContext} from 'react';
import MessageListElement from './MessageListElement';
import DiscussionContext from '../context/DiscussionContext';
import AuthContext from '../context/AuthContext';
import { useNavigate} from "react-router-dom";
import { UserType } from '../utils/UserType';

export default function MessagesList({ messages }) {
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [readMessages, setReadMessages] = useState([])
    const {discussions, setCurrentDiscussion, changeCurrentDiscussion} = useContext(DiscussionContext)
    const {user, onNavigate} = useContext(AuthContext)

    const navigate = useNavigate();

    const goToClickedDiscussion = (discussionId)=>{
        const discussion = discussions?.find((disc)=> disc.discussion?._id === discussionId);
        //console.log(discussion)
        changeCurrentDiscussion(discussion)
        navigate(`/${user.type === UserType.Professor ? "professor" : "student"}/discussions`)
        onNavigate()

    }

   
    useEffect(() => {
        // Filter unread and read messages
        const unread = messages
            .filter(message => message.seen === false)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
        const read = messages
            .filter(message => message.seen === true)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
        // Update state with filtered messages
        setUnreadMessages(unread);
        setReadMessages(read);
    }, [messages]);
    

    return (
        <div className="w-[20vw] h-max flex flex-col bg-bkg rounded-[30px] shadow border dark:border-none border-white backdrop-blur-[10px] justify-start items-start pt-4 gap-2 z-50 dark:bg-gradient-to-br from-[#1F1F29] to-[#4B1B44] dark:backdrop-blur-[60px]">
            {/* Number of unread messages */}
            <h3 className="mix-blend-hard-light text-content text-lg font-semibold font-inter leading-9 pl-2 dark:text-white">{`Messages ${unreadMessages.length > 0 ? `(${unreadMessages.length})`: ''}`}</h3>
            {/* Content */}
            <div className="w-full h-max max-h-[35vh] flex-col justify-start items-start gap-4 inline-flex rounded-b-[30px] overflow-y-auto scrollbar-hide">
                {/* display unread messages first */}
                {unreadMessages.length > 0 && unreadMessages.map((message, index) => (
                    <div className='w-full h-max max-h-[35vh]' onClick={()=> goToClickedDiscussion(message?.discussionId)}>
                        <MessageListElement key={index} message={message} />
                    </div>
                    
                ))}
                {readMessages.length > 0 && readMessages.map((message, index) => (
                    <div className='w-full h-max max-h-[35vh]' onClick={()=> goToClickedDiscussion(message?.discussionId)}>
                        <MessageListElement key={index} message={message} />
                    </div>
                    
                ))}
                {messages.length === 0 && (
                    <div className='w-full h-[35vh] flex-col justify-center items-center  inline-flex rounded-b-[30px]'>
                        <h4 className='text-content text-opacity-60 text-base font-normal font-inter leading-tight whitespace-nowrap text-end'>Aucun message reçu</h4>

                    </div>
                )}
            </div>
        </div>
    );
}
