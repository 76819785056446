const ReservationState = {
    Done : 'Faite',
    Scheduled : 'Programmée',
    Postponed : 'Reportée',
    Canceled : 'Annulée', // if a professor canceled a reservation after payment
    InProgress: 'En cours',
    Failed : 'Ratée',
    Pending: "En attente",
    Revoked: "Retirée", // if a professor send a reservation to a student and then revoke it
    Abandoned: "Abondonnée" // if a student canceld a reservation after/before payment
}
export {ReservationState};