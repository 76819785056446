import axios from "axios";
import { API_URL } from '../config/config';

class StudentService {
    constructor() {
        this.baseUrl = API_URL;
    }

    async getStudentById(studentId) {
        //console.log(studentId)
        try {
            if(!studentId._id){
                const response = await axios.get(
                    `${this.baseUrl}/students/${studentId}`
                );
                const data = response.data;
                return data;
            }else{
                const response = await axios.get(
                    `${this.baseUrl}/students/${studentId._id}`
                );
                const data = response.data;
                return data;
            }
        } catch (error) {
            console.error("Error fetching student by ID:", error);
            throw error;
        }
    }

    async getFavoriteCourses(studentId) {
        try {
            const response = await axios.get(
                `${this.baseUrl}/students/id/favorites/course?idStudent=${studentId}`
            );
            const data = response.data;
            return data;
        } catch (error) {
            console.error("Error fetching favorite courses:", error);
            throw error;
        }
    }

    async getFavoriteProfessors(studentId) {
        try {
            const response = await axios.get(
                `${this.baseUrl}/students/id/favorites/professor?idStudent=${studentId}`
            );
            const data = response.data;
            return data;
        } catch (error) {
            console.error("Error fetching favorite professors:", error);
            throw error;
        }
    }
    async getVirtualCardStudent(studentId) {
        try {
            const response = await axios.get(
                `${this.baseUrl}/virtualCard/${studentId}`
            );
            const data = response.data;
            return data;
        } catch (error) {
            console.error("Error fetching Virtual Card student:", error);
            throw error;
        }
    }

    async updateVirtualCardBalance(id, newBalance, transactionType) {
        try {
            const response = await axios.patch(
                `${this.baseUrl}/virtualCard/${id}/${transactionType}`,
                { balance: newBalance }
            );
            return response.data;
        } catch (error) {
            console.error("Error updating balance virtual Card:", error);
            throw error;
        }
    }

    async updateStudentProfile(studentId, updatedStudentData) {
        try {
            const response = await axios.patch(
                `${this.baseUrl}/students/profile/id?idStudent=${studentId}`,
                    updatedStudentData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
            );

            const data = response.data;
            return data;
        } catch (error) {
            console.error("Error updating student profile:", error);
            throw error;
        }
    }

    async canBeDeleted(id){
        try {    
          const response = await axios.get(`${this.baseUrl}/students/canbedeleted/${id}`);
          return response.data
        } catch (error) {
          //console.log(error)
          throw error
        }
      }
    
      async deleteStudentById(id){
        try {    
          const response = await axios.delete(`${this.baseUrl}/students/${id}`);
          return response.data
        } catch (error) {
          //console.log(error)
          throw error
        }
      }
}

export default StudentService;
