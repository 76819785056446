import axios from 'axios';
import { API_URL } from '../config/config';
class AdminService {
  constructor() {
    this.baseUrl = API_URL;
  }

  async getAdminVirtualCard() {
    try {
      const response = await axios.get(`${this.baseUrl}/virtualCard/Admin`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error('Error fetching Virtual Card student:', error);
      throw error;
    }
  }

  async updateVirtualCardBalance(id, newBalance, transactionType) {
    try {
      const response = await axios.patch(`${this.baseUrl}/virtualCard/${id}/${transactionType}`, { balance: newBalance });
      return response.data;
    } catch (error) {
      console.error('Error updating balance virtual Card:', error);
      throw error;
    }
  }

}

export default AdminService;
